// Dyspatch highlighting theme for react-prism-renderer

const theme = {
    plain: {
        color: "#FFFFFF",
        background: "#000000"
    },
    styles: [
        {
            types: ["prolog", "comment", "doctype", "cdata"],
            style: {
                color: "#93A1A1"
            }
        },
        {
            types: ["punctuation"],
            style: {
                color: "#26CFF9"
            }
        },
        {
            types: ["property", "tag", "boolean", "number", "constant", "symbol", "deleted"],
            style: {
                color: "#26CFF9"
            }
        },
        {
            types: ["selector", "attr-name", "string", "char", "builtin", "inserted"],
            style: {
                color: "#739AFA"
        
            }
        },
        {
            types: ["atrule", "attr-value", "keyword"],
            style: {
                color: "#76DEBE"
            }
        },
    ]
}

export default theme;