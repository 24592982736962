// other import statements for styling etc.

import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "./src/themes/dyspatch"
import "bootstrap/dist/css/bootstrap.min.css"

const codeStyle = {
    padding: "10px",
    backgroundColor: "black",
    whiteSpace: "pre-wrap",
    borderRadius: "10px",
}

const components = {
    pre: (props) => {
        const code = props.children.props.children.trim()
        return (
            <Highlight
                {...defaultProps}
                code={code}
                language="markup"
                theme={theme}
            >
                {({
                    className,
                    style,
                    tokens,
                    getLineProps,
                    getTokenProps,
                }) => (
                    <pre className={className} style={codeStyle}>
                        {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })}>
                                {line.map((token, key) => (
                                    <span {...getTokenProps({ token, key })} />
                                ))}
                            </div>
                        ))}
                    </pre>
                )}
            </Highlight>
        )
    },
    wrapper: ({ children }) => <>{children}</>,
}

export const wrapRootElement = ({ element }) => {
    return <MDXProvider components={components}>{element}</MDXProvider>
}
